import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import bannerStyle from './banner.module.css';

const Banner = () => (
  <section className={bannerStyle.BannerSection}>
    <Container fluid>
      <Row />
      <Col />
    </Container>
  </section>
);

export default Banner;
