import React from 'react'
import Banner from '../../../banner'

import SectionOne from './SectionOne'
//import './services.css'


const ServicesPage = () => (
    <>
    <Banner />
    <SectionOne />
    </>
)

export default ServicesPage